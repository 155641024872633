<template>
  <div class="video_cen">
    <div class="ship" v-if="datasp == 1">
      <div class="tit">
        <div class="chahao" @click="handelgb">
          <i class="el-icon-circle-close"></i>
        </div>
      </div>
      <video id="vid1" controls style="width: 53.125vw; height: 26.04167vw">
        <source
          style="width: 53.125vw; height: 26.04167vw"
          :src="url1"
          type="video/mp4"
        />
      </video>
    </div>
    <div class="vid_box">
      <el-carousel indicator-position="none" :autoplay="false" arrow="always">
        <el-carousel-item>
          <div class="cen_box">
            <div class="cen_left">
              <div class="left_tit">郑州摩尔港酒店（总装配）</div>
              <div class="left_cen">
                <div class="left_img">
                  <div class="img_zz">
                    <div class="ic_one" @click="handelSp"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cen_rig">
              <div class="rig_top">
                <div class="top_lef">
                  <div class="toplef_tit">郑州摩尔酒店（办公室换热间）</div>
                  <div class="toplef_cen">
                    <div class="toplef_cen1">
                      <div class="toplef_ic" @click="handelbangong"></div>
                    </div>
                  </div>
                </div>
                <div class="top_lef" style="margin-left: 1.04167vw">
                  <div class="toplef_tit">郑州摩尔酒店（总装配换热间）</div>
                  <div class="toplef_cen">
                    <div class="toplef_cen2">
                      <div class="toplef_ic" @click="handelhuanre"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rig_top">
                <div class="top_lef" style="margin-top: 1.04167vw">
                  <div class="toplef_tit">文旅大厦</div>
                  <div class="toplef_cen">
                    <div class="toplef_cen3">
                      <div class="toplef_ic" @click="handelwl"></div>
                    </div>
                  </div>
                </div>
                <div
                  class="top_lef"
                  style="margin-left: 1.04167vw; margin-top: 1.04167vw"
                >
                  <div class="toplef_tit">漯河项目</div>
                  <div class="toplef_cen">
                    <div class="toplef_cen4">
                      <div class="toplef_ic" @click="handellh"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="cen_box">
            <div class="cen_left">
              <div class="left_tit">南阳市中心医院（新院区）</div>
              <div class="left_cen">
                <div class="left_img1">
                  <div class="img_zz">
                    <div class="ic_one" @click="handelZxyy"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="cen_rig"></div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
// import { videoPlayer } from "vue-video-player";
// import "video.js/dist/video-js.css";
export default {
  components: {
    // videoPlayer,
  },
  data() {
    return {
      url1: require("@/assets/imgs/wldsbim.mp4"),
      datasp: 0,
      // imgurl1: require("@/assets/imgs/qt/img5.png"),
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {
    // this.datasp = 0;
  },
  // 组件实例挂载完毕
  mounted() {},

  destroyed() {},
  // 组件方法
  methods: {
    handelZxyy() {
      this.datasp = 1;
      this.url1 = require("@/assets/imgs/sp/jfzs1.mp4");
    },
    handelwl() {
      this.datasp = 1;
      this.url1 = require("@/assets/imgs/wldsbim.mp4");
    },
    handelbangong() {
      this.datasp = 1;
      this.url1 = require("@/assets/imgs/bangongshi.mp4");
    },
    handelhuanre() {
      this.datasp = 1;
      this.url1 = require("@/assets/imgs/huanre.mp4");
    },
    handelSp() {
      this.datasp = 1;
      this.url1 = require("@/assets/imgs/zong0n1.mp4");
    },
    handellh() {
      this.datasp = 1;
      this.url1 = require("@/assets/imgs/lh.mp4");
    },
    handelgb() {
      this.datasp = 0;
    },
  },
};
</script>

<style scoped lang='less'>
/deep/.el-icon-arrow-right {
  font-size: 28px !important;
}
/deep/.el-icon-arrow-left {
  font-size: 28px !important;
}
/deep/button {
  width: 60px;
  height: 60px;
  background-color: rgba(61, 72, 93, 0.7) !important;
}
/deep/.el-carousel__container {
  height: 480px;
}
/deep/.el-carousel__item h3 {
  height: 480px;
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 480px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  // height: 480px;
  background-color: transparent;
}

.el-carousel__item:nth-child(2n + 1) {
  // height: 480px;
  background-color: transparent;
}
.vid_box {
  width: 1300px;
  height: 480px;
  // border: 1px solid ghostwhite;
  margin: 0 auto;
}
.ship {
  width: 1020px;
  height: 550px;
  background-color: #191919;
  position: fixed;
  top: 200px;
  left: 20%;
  z-index: 999;
  .tit {
    width: 100%;
    height: 50px;
    background-color: rgba(10, 53, 90, 1);
    border: 1px solid #0a355a;
    .chahao {
      width: 40px;
      height: 40px;
      //border: 1px solid gold;
      margin-left: 960px;
      margin-top: 6px;
      font-size: 30px;
    }
  }
}
.video_cen {
  color: #ffffff;
  border-top: 0.5px solid transparent;
  // border: 1px solid gold;
  .cen_box {
    width: 1038px;
    height: 476px;
    //border: 1px solid goldenrod;
    margin: 0 auto;
    display: flex;
    .cen_left {
      width: 510px;
      height: 476px;
      //border: 1px solid gray;
      .left_tit {
        width: 100%;
        height: 50px;
        background-color: #0a355a;
        text-indent: 14px;
        line-height: 50px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
      .left_cen {
        width: 100%;
        height: 426px;
        background-color: #000000;
        border-top: 1px solid gray;
        .left_img {
          // width: 330px;
          // height: 229px;
          width: 100%;
          height: 100%;
          background-image: url("../../../assets/imgs/sp1.jpg");
          background-size: 100% 100%;
          // margin: 63px auto;
          .img_zz {
            width: 100%;
            height: 100%;
            border-top: 1px solid #000000;
            background-color: rgba(0, 0, 0, 0.3);
            .ic_one {
              width: 50px;
              height: 50px;
              margin: 160px auto;
              //border: 1px solid gold;
              background-image: url("../../../assets/imgs/qt/ic7.png");
              background-size: 100% 100%;
            }
          }
        }
        .left_img1 {
          // width: 330px;
          // height: 229px;
          width: 100%;
          height: 100%;
          background-image: url("../../../assets/imgs/sp/zxyy.png");
          background-size: 100% 100%;
          // margin: 63px auto;
          .img_zz {
            width: 100%;
            height: 100%;
            border-top: 1px solid #000000;
            background-color: rgba(0, 0, 0, 0.3);
            .ic_one {
              width: 50px;
              height: 50px;
              margin: 160px auto;
              //border: 1px solid gold;
              background-image: url("../../../assets/imgs/qt/ic7.png");
              background-size: 100% 100%;
            }
          }
        }
      }
    }
    .cen_rig {
      width: 510px;
      height: 476px;
      margin-left: 19px;
      .rig_top {
        height: 228px;
        width: 100%;
        display: flex;
        .top_lef {
          width: 245px;
          height: 228px;
          //border: 1px solid gray;
          .toplef_tit {
            width: 100%;
            height: 50px;
            background-color: #0a355a;
            text-indent: 14px;
            line-height: 50px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            color: #ffffff;
          }
          .toplef_cen {
            height: 178px;
            width: 100%;
            background-color: #000000;
            border-top: 0.5px solid #2a2a2a;
            .toplef_cen1 {
              border-top: 0.5px solid #2a2a2a;
              // width: 151px;
              // height: 102px;
              width: 100%;
              height: 100%;
              background-image: url("../../../assets/imgs/sp2.png");
              background-size: 100% 100%;
              // margin: 31px auto;
              .toplef_ic {
                width: 50px;
                height: 50px;
                background-image: url("../../../assets/imgs/qt/ic7.png");
                background-size: 100% 100%;
                margin: 60px auto;
              }
            }
            .toplef_cen2 {
              border-top: 0.5px solid #2a2a2a;
              // width: 151px;
              // height: 102px;
              width: 100%;
              height: 100%;
              background-image: url("../../../assets/imgs/sp3.png");
              background-size: 100% 100%;
              // margin: 31px auto;
              .toplef_ic {
                width: 50px;
                height: 50px;
                background-image: url("../../../assets/imgs/qt/ic7.png");
                background-size: 100% 100%;
                margin: 60px auto;
              }
            }
            .toplef_cen3 {
              border-top: 0.5px solid #2a2a2a;
              // width: 151px;
              // height: 102px;
              width: 100%;
              height: 100%;
              background-image: url("../../../assets/imgs/sp4.jpg");
              background-size: 100% 100%;
              // margin: 31px auto;
              .toplef_ic {
                width: 50px;
                height: 50px;
                background-image: url("../../../assets/imgs/qt/ic7.png");
                background-size: 100% 100%;
                margin: 60px auto;
              }
            }
            .toplef_cen4 {
              border-top: 0.5px solid #2a2a2a;
              // width: 151px;
              // height: 102px;
              width: 100%;
              height: 100%;
              background-image: url("../../../assets/imgs/sp5.jpg");
              background-size: 100% 100%;
              // margin: 31px auto;
              .toplef_ic {
                width: 50px;
                height: 50px;
                background-image: url("../../../assets/imgs/qt/ic7.png");
                background-size: 100% 100%;
                margin: 60px auto;
              }
            }
          }
        }
      }
      .rig_btm {
        height: 228px;
        width: 100%;
        margin-top: 17px;
        display: flex;
        border: 1px solid gray;
      }
    }
  }
}
</style>