<template>
  <div class="news">
    <div class="news_top">
      <h1 class="news_tit">新闻<span style="color: #1b1e20">动态</span></h1>
      <div class="tit_src">
        <el-link class="link" type="primary" @click="handelNews(0)"
          >了解更多 <i style="margin-left: 0.26042vw" class="icon_ic12"></i
        ></el-link>
      </div>
    </div>
    <div class="news_btm">
      <div class="news_cen">
        <div
          class="cen_data"
          v-for="(item, index) in list"
          :key="index"
          @click="handelNews(item.id)"
        >
          <div class="cen_img">
            <img :src="item.coverPhoto" alt="" />
          </div>
          <div class="cen_size">
            <p class="p_tit">{{ item.title }}</p>
            <p class="p_data" id="activetwo">
              {{ item.data }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { contentList } from "../../../assets/api/indexl";
export default {
  props: {
    list: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      state: 0, //导航状态
      scroll: 1,
      // list: [
        
      // ],
    };
  },
  inject: ["reload"],
  watch: {
    list: {
      handler(newss, old) {
        if(this.list.length > 1){
          console.log(newss , "111")
        }
        
        // this.handelConte()
      },
      immediate: false,
    },
  },
  // 组件初始化
  created() {
    this.handelConte();
    contentList().then((res) =>{
      this.list[0] = res.data.result[res.data.result.length-3]
      this.list[1] = res.data.result[res.data.result.length-2]
      this.list[2] = res.data.result[res.data.result.length-1]
    })
  },
  // 组件实例挂载完毕
  mounted() {},
  destroyed() {},
  // 组件方法
  methods: {
    async handelConte() {
      // const res = await contentList();
      // this.list[0] = res.data.result[res.data.result.length - 3];
      // this.list[1] = res.data.result[res.data.result.length - 2];
      // this.list[2] = res.data.result[res.data.result.length - 1];
      // console.log(this.list)
      contentList().then((res) => {
        this.list[0] = res.data.result[res.data.result.length - 3];
        this.list[1] = res.data.result[res.data.result.length - 2];
        this.list[2] = res.data.result[res.data.result.length - 1];
        console.log(this.list)
      });
    },
    handelNews(type) {
      console.log(type);
      this.$router.push({ name: "/newsxq" });
      localStorage.setItem("newkey", type);
    },
  },
};
</script>

<style scoped lang='less'>
.news {
  width: 100%;
  height: 100%;

  //   margin-top: 48px;
  .news_top {
    width: 100%;
    height: 74px;
    // display: flex;
    // border: 1px solid gold;
    position: relative;
    .news_tit {
      width: 112px;
      height: 42px;
      font-size: 28px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #0072ff;
      line-height: 42px;
      margin: 0 auto;
      //margin-left: 664px;
      //   border: 1px solid gold;
    }
    .tit_src {
      //width: 115px;

      position: absolute;
      top: 10px;
      height: 24px;
      //border: 1px solid gold;
      z-index: 1;
      margin-left: 73%;
      //margin-top: 50px;
      white-space: nowrap;
      word-wrap: normal;
      word-break: keep-all;
      .link {
        font-size: 14px;
        // font-weight: 600;
        margin-top: -20px;
      }
      i {
        color: #0072ff;
        // margin-left: 10px;
        //margin-top: 5px;
        //border: 1px solid gold;
      }
    }
  }

  .news_btm {
    width: 100%;
    height: 478px;
    background-image: url("../../../assets/imgs/yi_xwbj.png");
    background-size: 100% 100%;
    // border: 1px solid gold;
    display: flex;
    .news_cen {
      width: 1040px;
      height: 382px;
      margin: auto;
      display: flex;
      .cen_data:hover {
        border-bottom: 4px solid #0072ff;
      }
      .cen_data {
        cursor: pointer;
        width: 333px;
        height: 100%;
        // border-bottom: 4px solid #0072ff;
        .cen_img {
          width: 100%;
          height: 248px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .cen_size {
          width: 100%;
          height: 108px;
          background-color: #ffffff;
          padding-top: 24px;
          .p_tit {
            width: 285px;
            height: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 600;
            color: #1b1e20;
            line-height: 22px;
            margin: 0 auto;
            // text-align: left;
            // border: 1px solid gold;
          }
          .p_data {
            width: 285px;
            height: 44px;
            font-size: 14px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #858585;
            line-height: 22px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            margin: 0 auto;
            margin-top: 8px;
          }
        }
      }
      .cen_data:nth-child(2) {
        margin-left: 20px;
      }
      .cen_data:nth-child(3) {
        margin-left: 20px;
      }
    }
  }
}
</style>