<template>
  <div class="application1">
    <div class="tit">YCM011智慧管理器无面板应用</div>
    <div class="scene">
      <div class="scene_left">
        <div class="p_tit">
          <p class="icon_tit"></p>
          <p class="icon_tit1">使用场景</p>
        </div>
        <p class="p_cen">
          在一些公共场所，为了避免非工作人员误作，供水温度探头可定义为房间温度，无需面板即可使用,远程或现场通过智能管理平台设置开关机时间、温度调节等操作。
        </p>
      </div>
      <div class="scene_rig">
        <!-- <img src="../../../assets/imgs/qt/img6.png" alt="" /> -->
        <img v-lazy='imgurl1' alt="" />
      </div>
    </div>
    <div class="characteristic">
      <div class="characteristic_tit">
        <div class="ic_tit"></div>
        <div class="ic_data">YCM011产品特性</div>
      </div>
      <div class="characteristic_box">
        <div class="box">
          <p>两路温度传感器分别监测供、回水温度</p>
        </div>
        <div class="box">
          <p>一路模拟量输出控制模拟量设备</p>
        </div>
        <div class="box">
          <p>路数字量输出分别控制地暖、风盘电动阀、风盘高中低风速</p>
        </div>
        <div class="box">
          <p>一路 RS485 连接YCD501,配置用户参数</p>
        </div>
        <div class="box">
          <p>一路模拟量输入采集模拟量数据</p>
        </div>
      </div>
    </div>
    <div class="btm_box">
      <div class="btm_left">
        <p class="btm_tit">YCE101一拖多场景应用</p>
        <div class="btm_title_box">
          <div class="ic_box"></div>
          <p class="btm_title">使用场景</p>
        </div>

        <p class="btm-data">
          大型商场、餐饮、办公楼等场所的室内温度控制;一个温控器控制多台风机盘管,减少重复操作,且美观、大方、整洁。
        </p>
      </div>
      <div class="btm_rig">
        <!-- <img src="../../../assets/imgs/qt/img8.png" alt="" /> -->
        <img v-lazy='imgurl2' alt="" />
      </div>
    </div>
    <div class="yc_title_box">
      <div class="yi_ic"></div>
      <p class="yc_tit">YCE101产品特性</p>
    </div>

    <div class="yc_box">
      <div class="box" id="activefour">
        <p>一个温控面板控制多台风盘</p>
      </div>
      <div class="box"><p>安装在吊顶，减少布线,方便施工</p></div>
      <div class="box"><p>选用工业级电子元件，寿命长稳定可靠</p></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgurl1:require("@/assets/imgs/qt/img6.png"),
      imgurl2:require("@/assets/imgs/ht/xhcpyy.png"),
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {},

  destroyed() {},
  // 组件方法
  methods: {},
};
</script>

<style scoped lang='less'>
.application1 {
  background-color: #ffffff;
  border-top: 1px solid ghostwhite;
  .tit {
    width: 80%;
    height: 28px;
    margin-left: 24px;
    margin-top: 25px;

    font-size: 18px;
    //font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #1b1e20;
    line-height: 28px;
  }
  .scene {
    width: 100%;
    height: 243px;
    margin-top: 52px;
    display: flex;
    //border: 1px solid gray;
    .scene_left {
      width: 410px;
      height: 100%;
      margin-left: 29px;
      //border: 1px solid gray;
      .p_tit {
        width: 100%;
        height: 32px;
        //border: 1px solid gray;
        display: flex;
        .icon_tit {
          width: 32px;
          height: 32px;
          background-image: url("../../../assets/imgs/yc_zyic1.png");
          background-size: 100% 100%;
          //border: 1px solid gray;
        }

        .icon_tit1 {
          width: 50%;
          height: 32px;
          margin-left: 10px;
          //border: 1px solid gray;
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #1b1e20;
          line-height: 32px;
        }
      }
      .p_cen {
        width: 364px;
        height: 66px;
        margin-top: 11px;
        margin-left: 36px;
        //border: 1px solid gray;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #3d485d;
        line-height: 22px;
      }
    }
    .scene_rig {
      width: 374px;
      height: 100%;
      margin-left: 104px;
      //border: 2px dashed gray;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .characteristic {
    width: 994px;
    height: 321px;
    margin-top: 2px;
    margin-left: 25px;
    border-bottom: 4px solid #f6f6f6;
    .characteristic_tit {
      width: 50%;
      height: 32px;
      margin-left: 3px;
      display: flex;
      //border: 1px solid gray;
      .ic_tit {
        width: 32px;
        height: 32px;
        background-image: url("../../../assets/imgs/yc_zyic2.png");
      background-size: 100% 100%;
        //border: 1px solid gray;
      }
      .ic_data {
        width: 60%;
        height: 32px;
        line-height: 32px;
        margin-left: 5px;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #1b1e20;
      }
    }
    .characteristic_box {
      width: 100%;
      height: 217px;
      margin-top: 24px;
      //border: 1px dashed gray;
      display: flex;
      .box {
        width: 182px;
        height: 217px;
        //border: 2px dashed gray;

        p {
          width: 160px;
          height: 84px;
          margin: 117px auto;
          text-align: center;
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #3d485d;
          line-height: 28px;
        }
      }
      .box:nth-child(1) {
        background-image: url("../../../assets/imgs/qt/xbj1.png");
        background-size: 100% 100%;
      }
      .box:nth-child(2) {
        background-image: url("../../../assets/imgs/qt/xbj2.png");
        background-size: 100% 100%;
        margin-left: 21px;
      }
      .box:nth-child(3) {
        background-image: url("../../../assets/imgs/qt/xbj3.png");
        background-size: 100% 100%;
        margin-left: 21px;
      }
      .box:nth-child(4) {
        background-image: url("../../../assets/imgs/qt/xbj4.png");
        background-size: 100% 100%;
        margin-left: 21px;
      }
      .box:nth-child(5) {
        background-image: url("../../../assets/imgs/qt/xbj5.png");
        background-size: 100% 100%;
        margin-left: 21px;
      }
    }
  }
  .btm_box {
    width: 900px;
    height: 308px;
    margin-top: 52px;
    margin-left: 25px;
    display: flex;
    //border: 1px solid gray;
    .btm_left {
      width: 358px;
      height: 100%;
      //border: 1px solid gray;
      .btm_tit {
        width: 100%;
        height: 28px;
        //border: 1px solid gray;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #1b1e20;
        line-height: 28px;
      }
      .btm_title_box {
        width: 50%;
        height: 28px;
        //margin-left: 33px;
        margin-top: 25px;
        display: flex;
        //border: 1px solid gray;
        .ic_box {
          width: 32px;
          height: 28px;
          background-image: url("../../../assets/imgs/yc_zyic1.png");
          background-size: 100% 100%;
          //border: 1px solid gray;
        }
        .btm_title {
          //
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #1b1e20;
          line-height: 28px;
          margin-left: 8px;
        }
      }

      .btm-data {
        width: 358px;
        height: 44px;
        margin-left: 33px;
        margin-top: 11px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #3d485d;
        line-height: 22px;
      }
    }
    .btm_rig {
      width: 426px;
      height: 308px;
      margin-left: 150px;
      // border: 2px dashed gray;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .yc_title_box {
    width: 50%;
    height: 29px;
    //border: 1px solid gray;
    display: flex;
    .yi_ic {
      width: 31px;
      height: 29px;
      background-image: url("../../../assets/imgs/yc_zyic2.png");
      background-size: 100% 100%;
      //border: 1px solid gray;
      margin-left: 26px;
    }
    .yc_tit {
      width: 80%;
      height: 29px;
      margin-left: 8px;
      font-size: 18px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #1b1e20;
      line-height: 29px;
      //border: 1px solid gray;
    }
  }

  .yc_box {
    width: 991px;
    height: 217px;
    margin-top: 26px;
    margin-left: 25px;
    display: flex;
    //border: 1px solid gray;
    .box {
      width: 317px;
      height: 217px;
      //border: 2px dashed gray;
      p {
        width: 160px;
        height: 56px;
        margin-left: 150px;
        margin-top: 80px;
        font-size: 16px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #3d485d;
        line-height: 28px;
        //border: 1px dashed gray;
      }
    }
    .box:nth-child(1) {
      background-image: url("../../../assets/imgs/qt/xbj6.png");
      background-size: 100% 100%;
    }
    .box:nth-child(2) {
      background-image: url("../../../assets/imgs/qt/xbj7.png");
      background-size: 100% 100%;
      margin-left: 12px;
    }
    .box:nth-child(3) {
      background-image: url("../../../assets/imgs/qt/xbj8.png");
      background-size: 100% 100%;
      margin-left: 28px;
    }
  }
}
</style>