<template>
  <div class="app">
    <div class="swp" id="activeeight">
      <img :src="imgurl" alt="" />
      <!-- <div class="swpbtm" id="activeone"></div> -->
    </div>
    <div id="activeone" style="height: 1.04167vw"></div>

    <h1 class="p_tit">公司简介</h1>
    <div class="brief_introduction">
      <Product></Product>
    </div>
    <div class="news_zw" id="active"></div>
    <div class="news">
      <div class="news_top">
        <h1 class="news_tit">新闻<span style="color: #1b1e20">动态</span></h1>
        <div class="tit_src">
          <el-link class="link" type="primary" @click="handelNews(listarr.length)"
            >了解更多 <i style="margin-left: 0.26042vw" class="icon_ic12"></i
          ></el-link>
        </div>
      </div>
      <div class="news_btm">
        <div class="news_cen">
          <div
            class="cen_data"
            v-for="(item, index) in list"
            :key="index"
            @click="handelNews(item.id)"
          >
            <div class="cen_img">
              <img :src="item.coverPhoto" alt="" />
            </div>
            <div class="cen_size">
              <p class="p_tit">{{ item.title }}</p>
              <p class="p_data" id="activetwo">
                {{ item.data }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- <News :list="list"></News> -->
      <div></div>
    </div>
    <!-- <div  style="height:2.08333vw"></div> -->
    <h1 class="p_tit" style="padding-top: 2.08333vw; padding-bottom: 0.52083vw">
      产品介绍
    </h1>
    <div class="product"><Productint></Productint></div>
    <h1 class="p_tit1">机房 BIM 设计</h1>
    <div class="video">
      <div class="video_tit"><Videotit></Videotit></div>
      <div class="video_cen"><Video></Video></div>
      <div id="activethree"></div>
    </div>
    <h1 class="p_tit1">产品应用</h1>
    <div class="application1"><Application></Application></div>
    <div></div>
    <h1 class="p_tit1">系统架构</h1>
    <div class="architecture">
      <div class="architecture_img">
        <!-- <img src="../../assets/imgs/qt/img1.png" alt="" /> -->
        <img v-lazy="imgurl2" alt="" />
      </div>
      <div id="activefive"></div>
    </div>

    <h1 class="p_tit1">智慧能源管控流程</h1>
    <div class="architecture">
      <div class="architecture_img1">
        <!-- <img src="../../assets/imgs/qt/img2.png" alt="" /> -->
        <img v-lazy="imgurl3" alt="" />
      </div>
    </div>
    <div id="activesix"></div>
    <h1 class="p_tit1">二次循环在变流量中央空调系统中的应用</h1>
    <div class="application"><Secondary></Secondary></div>
    <div class="btm" id="activeseven">
      <div class="btm_carousel"><Carousel :datalist="datalist"></Carousel></div>
      <div class="btm_bottom"><Contact></Contact></div>
    </div>
    <div class="box" v-if="statebr == 1"><Navigati></Navigati></div>
    <!-- <div class="ccc">
      <img src="../../assets/imgs/zt.png" alt="">
    </div> -->
  </div>
</template>

<script>
import Navigati from "../../components/Navigati.vue";
import Product from "../home/components/Product.vue";
import Productint from "../home/components/Productint.vue";
import Videotit from "../home/components/Videotit.vue";
import Video from "../home/components/Video.vue";
import Application from "../home/components/Application.vue";
import Secondary from "../home/components/Secondary.vue";
import Carousel from "../home/components/Carousel.vue";
import Contact from "../home/components/Contact.vue";
import News from "../home/components/News.vue";
import { customCaseList, contentList } from "../../assets/api/indexl";
export default {
  props: {
    scroll: {},
  },
  components: {
    Navigati,
    Product,
    Productint,
    Videotit,
    Video,
    Application,
    Secondary,
    Carousel,
    Contact,
    // News,
  },
  data() {
    return {
      datalist: [],
      list: [],
      statebr: 0,
      // imgurl:'../../assets/imgs/bj.png',
      imgurl: require("@/assets/imgs/bj.png"),
      imgurl2: require("@/assets/imgs/ht/xhxtjg.png"),
      imgurl3: require("@/assets/imgs/ht/xhgllc.png"),
    };
  },
  inject: ["reload"],
  watch: {
    scroll: {
      handler(newss, old) {
        if (newss > 500) {
          this.statebr = 1;
        } else {
          this.statebr = 0;
        }
      },
      immediate: false,
    },
    list: {
      handler(newss, old) {
        console.log("rrrr");
      },
      immediate: false,
    },
    listarr:[],
  },
  // 组件初始化
  created() {
    contentList().then((res1) => {
      this.listarr = JSON.parse(JSON.stringify(res1.data.result));
      this.list = res1.data.result.slice(length - 3);
    });
    customCaseList().then((res) => {
      console.log(res, "1111");
      this.datalist = res.data.result;
    });
    var currentURL = window.location.href;
    this.$emit("passfunction", currentURL);
  },
  // 组件实例挂载完毕
  mounted() {},
  destroyed() {},
  // 组件方法
  methods: {
    // async handelcone() {
    //   const res1 = await contentList();
    //   this.list[0] = res1.data.result[res1.data.result.length - 3];
    //   this.list[1] = res1.data.result[res1.data.result.length - 2];
    //   this.list[2] = res1.data.result[res1.data.result.length - 1];
    //   // this.reload()
    // },
    handelNews(type) {
      console.log(type);
      this.$router.push({ name: "/newsxq" });
      localStorage.setItem("newkey", type);
    },
  },
};
</script>

<style scoped lang='less'>
.ccc {
  width: 200px;
  height: 200px;
  box-shadow: inset 0px 1px 31px 2px #ffb200;
  border: 1px solid #ffb200;
  margin: 0 auto;
  margin-top: 10px;
  img {
    margin-top: 30px;
    margin-left: 35px;
  }
}
.app {
  width: 100%;
  height: 99%;
  margin-top: 80px;
  background-color: #f9f9f9;
  // overflow: hidden;
  // overflow-y: scroll;
  .box {
    width: 172px;
    height: 400px;
    // border: 1px solid gold;
    // width: 100%;
    // height: 100%;
    margin-left: 10px;
    background-color: #ffffff;
    box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 0.5);
    position: fixed;
    //left: 10px;
    top: 300px;
    z-index: 999;
    //border: 1px solid gold;
  }
  .swp {
    width: 100%;
    border-top: 0.5px solid #101820;
    min-height: 662px;
    // background-image: url("../../assets/imgs/bj.png");
    // background-size: 100% 100%;
    margin-top: 80px;

    img {
      width: 100%;
      height: 100%;
    }
    .swpbtm {
      width: 100%;
      //height: 1px;
      margin-top: 602px;
      // background-color: red;
    }
  }
  .p_tit {
    width: 100%;
    // font-size: 28px;
    // font-weight: 900;
    padding-top: 22px;
    font-size: 28px;
    text-align: center;
  }
  .brief_introduction {
    //width: 1387px;
    width: 1040px;
    height: 634px;
    // background-color: #ffffff;
    // border: 1px solid gray;
    margin: 0 auto;

    // border-bottom: 1px solid transparent;
    //padding: 32px;
  }
  .news_zw {
    height: 48px;
    // border: 1px solid gold;
  }
  .news {
    width: 100%;
    height: 552px;
    .news_top {
      width: 100%;
      height: 74px;
      // display: flex;
      // border: 1px solid gold;
      position: relative;
      .news_tit {
        width: 112px;
        height: 42px;
        font-size: 28px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #0072ff;
        line-height: 42px;
        margin: 0 auto;
        //margin-left: 664px;
        //   border: 1px solid gold;
      }
      .tit_src {
        //width: 115px;

        position: absolute;
        top: 10px;
        height: 24px;
        //border: 1px solid gold;
        z-index: 1;
        margin-left: 73%;
        //margin-top: 50px;
        white-space: nowrap;
        word-wrap: normal;
        word-break: keep-all;
        .link {
          font-size: 14px;
          // font-weight: 600;
          margin-top: -20px;
        }
        i {
          color: #0072ff;
          // margin-left: 10px;
          //margin-top: 5px;
          //border: 1px solid gold;
        }
      }
    }

    .news_btm {
      width: 100%;
      height: 478px;
      background-image: url("../../assets/imgs/yi_xwbj.png");
      background-size: 100% 100%;
      // border: 1px solid gold;
      display: flex;
      .news_cen {
        width: 1040px;
        height: 382px;
        margin: auto;
        display: flex;
        .cen_data:hover {
          border-bottom: 4px solid #0072ff;
        }
        .cen_data {
          cursor: pointer;
          width: 333px;
          height: 100%;
          // border-bottom: 4px solid #0072ff;
          .cen_img {
            width: 100%;
            height: 248px;
            // border: 1px solid gold;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .cen_size {
            width: 100%;
            height: 108px;
            background-color: #ffffff;
            padding-top: 24px;
            .p_tit {
              width: 285px;
              height: 22px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-size: 16px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 600;
              color: #1b1e20;
              line-height: 22px;
              margin: 0 auto;
              // text-align: left;
              // border: 1px solid gold;
            }
            .p_data {
              width: 285px;
              height: 44px;
              font-size: 14px;
              font-family: PingFangSC, PingFang SC;
              font-weight: 400;
              color: #858585;
              line-height: 22px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              margin: 0 auto;
              margin-top: 8px;
            }
          }
        }
        .cen_data:nth-child(2) {
          margin-left: 20px;
        }
        .cen_data:nth-child(3) {
          margin-left: 20px;
        }
      }
    }
  }
  .product {
    width: 1040px;
    min-height: 1303px;
    //border: 1px solid gray;
    margin: 0 auto;
  }
  .p_tit1 {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 15px;
    font-size: 28px;
    text-align: center;
  }
  .video {
    width: 100%;
    min-height: 903px;
    background-image: url("../../assets/imgs/qt/bj3.png");
    background-size: 100% 100%;

    .video_tit {
      width: 100%;
      min-height: 363px;
      //border: 1px solid gray;
      background-color: rgba(46, 65, 106, 0.3);
    }
    .video_cen {
      width: 100%;
      min-height: 540px;
      //border: 1px solid gray;
    }
  }
  .application1:hover {
    box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 1);
  }
  .application1 {
    width: 1040px;
    min-height: 1339px;
    //border: 1px solid gray;
    margin: 0 auto;
    box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 0.5);
  }
  .architecture:hover {
    box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 1);
  }
  .architecture {
    width: 1041px;
    min-height: 543px;
    background-color: #ffffff;
    border-top: 1px solid #ffffff;
    box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 0.5);
    margin: 0 auto;
    .architecture_img {
      width: 947px;
      height: 368px;
      margin: 86px auto;
      // border: 2px dashed gray;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .architecture_img1 {
      margin: 37px auto;
      width: 881px;
      height: 485px;
      //border: 2px dashed gray;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .application:hover {
    box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 1);
  }
  .application {
    width: 1041px;
    min-height: 510px;
    background-color: #ffffff;
    //border: 1px solid gray;
    margin: 0 auto;
    box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 0.5);
  }
  .btm {
    width: 100%;
    height: 845px;
    //height: 631px;
    //border: 1px solid gray;
    margin-top: 80px;
    .btm_carousel {
      width: 100%;
      height: 631px;
      //border: 1px solid #ffffff;
      background-image: url("../../assets/imgs/qt/bj2.png");
      background-size: 100% 100%;
    }
    .btm_bottom {
      width: 100%;
      height: 214px;
      background-color: #101820;
    }
  }
}
</style>