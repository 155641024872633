var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product"},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"box_left"},[_c('div',{staticClass:"left_tit"},[_c('p',{staticClass:"p_size"},[_vm._v("末端设备智慧管理器系列")]),_c('p',{staticClass:"p_src"},[_c('el-link',{staticClass:"link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.ccc(-1)}}},[_vm._v("了解更多"),_c('i',{staticClass:"icon_ic12"})])],1)]),_c('div',{staticClass:"left_cen"}),_c('div',{staticClass:"left_img"},[_c('img',{attrs:{"src":_vm.imgurltop1,"alt":""}})])]),_c('div',{staticClass:"box_rig"},[_c('div',{staticClass:"left_tit"},[_c('p',{staticClass:"p_size"},[_vm._v("控制面板系列")]),_c('p',{staticClass:"p_src"},[_c('el-link',{staticClass:"link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.ccc(0)}}},[_vm._v("了解更多"),_c('i',{staticClass:"icon_ic12"})])],1)]),_c('div',{staticClass:"left_cen"}),_c('div',{staticClass:"left_img"},[_c('img',{attrs:{"src":_vm.imgurltop2,"alt":""}})])])]),_c('div',{staticClass:"btm"},_vm._l((_vm.datalis),function(item,index){return _c('div',{key:index,staticClass:"box_cen"},[_c('div',{staticClass:"tit"},[_c('div',{staticClass:"tit_tit"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"tit_src"},[_c('el-link',{staticClass:"link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.ccc(item.code)}}},[_vm._v("了解更多"),_c('i',{staticClass:"icon_ic12"})])],1)]),_c('div',{staticClass:"cen"},[_vm._v(_vm._s(item.data))]),_c('div',{staticClass:"img",style:(item.code == 1
            ? 'width:8.54167vw ; height:5.20833vw'
            : item.code == 2
            ? 'width:1.66667vw ; height:7.76042vw'
            : item.code == 3
            ? 'width:10.9375vw ; height:8.22917vw'
            : item.code == 4
            ? 'width:4.47917vw ; height:8.64583vw'
            : item.code == 5
            ? 'width:8.59375vw ; height:6.45833vw'
            : item.code == 6
            ? 'width:9.16667vw ; height:6.66667vw'
            : item.code == 7
            ? 'width:7.91667vw ; height:7.8125vw'
            : item.code == 8
            ? 'width:6.82292vw ; height:7.8125vw'
            : item.code == 9
            ? 'width:3.75vw ; height:8.95833vw'
            : '')},[_c('img',{staticClass:"img1",attrs:{"src":item.imgurl,"alt":""}})])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }