<template>
  <div class="product">
    <div class="top">
      <div class="box_left">
        <div class="left_tit">
          <p class="p_size">末端设备智慧管理器系列</p>
          <p class="p_src">
            <el-link class="link" type="primary" @click="ccc(-1)"
              >了解更多<i class="icon_ic12"></i
            ></el-link>
          </p>
        </div>
        <!-- <div class="left_cen">YCM011、YCM051(主)、YCM041(从)</div> -->
        <div class="left_cen"></div>
        <div class="left_img">
          <img :src='imgurltop1' alt="" />
        </div>
        
      </div>
      <div class="box_rig">
        <div class="left_tit">
          <p class="p_size">控制面板系列</p>
          <p class="p_src">
            <el-link class="link" type="primary" @click="ccc(0)"
              >了解更多<i class="icon_ic12"></i
            ></el-link>
          </p>
        </div>
        <!-- <div class="left_cen">YCD501</div> -->
        <div class="left_cen"></div>
        <div class="left_img">
          <img :src='imgurltop2' alt="" />
        </div>
      </div>
    </div>
    <div class="btm">
      <div class="box_cen" v-for="(item, index) in datalis" :key="index">
        <div class="tit">
          <div class="tit_tit">{{ item.title }}</div>
          <div class="tit_src">
            <el-link class="link" type="primary" @click="ccc(item.code)"
              >了解更多<i class="icon_ic12"></i
            ></el-link>
          </div>
        </div>
        <div class="cen">{{ item.data }}</div>
        <div
          class="img"
          :style="
            item.code == 1
              ? 'width:8.54167vw ; height:5.20833vw'
              : item.code == 2
              ? 'width:1.66667vw ; height:7.76042vw'
              : item.code == 3
              ? 'width:10.9375vw ; height:8.22917vw'
              : item.code == 4
              ? 'width:4.47917vw ; height:8.64583vw'
              : item.code == 5
              ? 'width:8.59375vw ; height:6.45833vw'
              : item.code == 6
              ? 'width:9.16667vw ; height:6.66667vw'
              : item.code == 7
              ? 'width:7.91667vw ; height:7.8125vw'
              : item.code == 8
              ? 'width:6.82292vw ; height:7.8125vw'
              : item.code == 9
              ? 'width:3.75vw ; height:8.95833vw'
              : ''
          "
        >
          <img class="img1" :src="item.imgurl" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      datalis: [
        {
          code: 1,
          title: "扩展模块系列",
          data: "一拖四模块",
          imgurl: require("@/assets/imgs/ht/YCE101.png"),
        },
        {
          code: 2,
          title: "无线传感器系列",
          data: "无线温度",
          imgurl: require("@/assets/imgs/ht/YCT0011.png"),
        },
        {
          code: 3,
          title: "无线网关系列",
          data: "水源井智慧管理器设备",
          imgurl: require("@/assets/imgs/ht/wangguan.png"),
        },
        {
          code: 4,
          title: "无线流量计系列",
          data: "无线流量计设备",
          imgurl: require("@/assets/imgs/v1016.png"),
        },
        {
          code: 5,
          title: "无线网关系列",
          data: "水源井智慧管理器设备",
          imgurl: require("@/assets/imgs/ht/wxwg.png"),
        },
        {
          code: 6,
          title: "手持调试仪系列",
          data: "手持调试设备",
          imgurl: require("@/assets/imgs/ht/YCP01.png"),
        },
        {
          code: 7,
          title: "阀门系列",
          data: "三线制电动球阀",
          imgurl: require("@/assets/imgs/ht/YCV2001.png"),
        },
        {
          code: 8,
          title: "四通球阀 MQV-4",
          data: "--",
          imgurl: require("@/assets/imgs/ht/MQV-4.png"),
        },
        {
          code: 9,
          title: "水源井井管",
          data: "--",
          imgurl: require("@/assets/imgs/ht/WX.png"),
        },
      ],
      imgurltop1:require("@/assets/imgs/ht/IMG_7171.png"),
      imgurltop2:require("@/assets/imgs/ht/mb.png"),
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {},

  destroyed() {},
  // 组件方法
  methods: {
    ccc(code) {
      // console.log(code);
      
        this.$router.push({ name: "/more"});
        localStorage.setItem("key", code);
     
    },
  },
};
</script>

<style scoped lang="less">
.product {
  .top {
    width: 100%;
    height: 373px;
    //border: 1px solid gray;
    display: flex;
    .box_left:hover {
      box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 1);
    }
    .box_left {
      width: 510px;
      height: 100%;
      //border: 1px solid gray;
      background-color: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 0.5);
      .left_tit {
        width: 100%;
        height: 24px;
        //border: 1px solid gray;
        margin-top: 24px;
        display: flex;
        .p_size {
          width: 176px;
          height: 24px;
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 900;
          color: #1b1e20;
          line-height: 24px;
          margin-left: 40px;
          color: #1b1e20;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
        }
        .p_src {
          width: 85px;
          height: 24px;
          //line-height: 32px;
          //border: 1px solid gray;
          margin-left: 170px;
          font-size: 16px;
          //font-weight: 900;
          //color: #1b1e20;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
          .link {
            font-size: 14px;
            //font-weight: 600;
            margin-top: -20px;
          }
          i {
            color: #0072ff;
            margin-left: 8px;
            //margin-top: 5px;
            //border: 1px solid gold;
          }
        }
      }
      .left_cen {
        width: 80%;
        height: 22px;
        //border: 1px solid gray;
        margin-top: 8px;
        margin-left: 40px;
        font-size: 16px;
        color: #3d485d;
      }
      .left_img {
        width: 175px;
        height: 212px;
        //border: 2px dashed gray;
        // margin-top: 37px;
        // margin-left: auto;
        margin: 37px auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .box_rig:hover {
      box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 1);
    }
    .box_rig {
      margin-left: 20px;
      width: 510px;
      height: 100%;
      background-color: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 0.5);
      .left_tit {
        width: 100%;
        height: 24px;
        //border: 1px solid gray;
        margin-top: 24px;
        display: flex;
        .p_size {
          width: 176px;
          height: 24px;
          font-size: 18px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 900;
          color: #1b1e20;
          line-height: 24px;
          margin-left: 40px;
          color: #1b1e20;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
        }
        .p_src {
          width: 85px;
          height: 24px;
          //line-height: 32px;
          //border: 1px solid gray;
          margin-left: 170px;
          font-size: 16px;
          //font-weight: 900;
          //color: #1b1e20;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
          .link {
            font-size: 14px;
            //font-weight: 600;
            margin-top: -20px;
          }
          i {
            color: #0072ff;
            margin-left: 8px;
            //margin-top: 5px;
            //border: 1px solid gold;
          }
        }
      }
      .left_cen {
        width: 80%;
        height: 22px;
        //border: 1px solid gray;
        margin-top: 8px;
        margin-left: 40px;
        font-size: 16px;
        color: #3d485d;
      }
      .left_img {
        width: 196px;
        height: 201px;
        //border: 2px dashed gray;
        // margin-top: 37px;
        // margin-left: auto;
        margin: 37px auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .btm {
    width: 100%;
    height: 930px;
    margin-top: 20px;
    // border: 1px solid gray;
    display: flex;
    flex-wrap: wrap;
    .box_cen:hover {
      box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 1);
    }
    .box_cen {
      width: 333px;
      height: 290px;
      background-color: #ffffff;
      box-shadow: 0px 2px 15px 0px rgba(216, 216, 216, 0.5);
      .tit {
        width: 100%;
        height: 24px;
        margin-top: 24px;
        //border: 1px solid gray;
        display: flex;
        .tit_tit {
          width: 96px;
          height: 24px;
          margin-left: 24px;
          line-height: 24px;
          font-size: 18px;
          font-weight: 900;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
          //border: 1px solid gray;
        }
        .tit_src {
          //width: 115px;
          height: 24px;
          //border: 1px solid gold;
          margin-left: 107px;
          white-space: nowrap;
          word-wrap: normal;
          word-break: keep-all;
          .link {
            font-size: 14px;
            // font-weight: 600;
            margin-top: -20px;
          }
          i {
            color: #0072ff;
            margin-left: 8px;
            //margin-top: 5px;
            //border: 1px solid gold;
          }
        }
      }
      .cen {
        width: 80%;
        height: 24px;
        margin-left: 24px;
        margin-top: 8px;
        //border: 1px solid gray;
        font-size: 16px;
        color: #3d485d;
      }
      .img {
        margin: 24px auto;
        // width: 220px;
        // height: 220px;
        // border: 2px dashed gray;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .box_cen:nth-child(2) {
      margin-left: 20px;
      margin-right: 20px;
    }
    .box_cen:nth-child(5) {
      margin-left: 20px;
      margin-right: 20px;
    }
    .box_cen:nth-child(8) {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
</style>
