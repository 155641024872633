<template>
  <div class="video_tit">
    <div class="box">
      <div class="box_left">
        <p class="p_tit">可视化，所见即所得</p>
        <p class="p_tit1">
          实现机房 1:1
          复刻，使用三维立体建模技术模拟安装，使所有安装工人，都能够看到完工后的结果。
        </p>
      </div>
      <div class="box_rig">
        <p class="p_tit">协调性，碰撞干涉检查</p>
        <p class="p_tit1">
          整体把控机房设备的布局，并进行干涉检查，优化设计方案，高效、美观，同时降低机房安装人工成本。
        </p>
      </div>
      <div class="box_btm">
        <p class="p_tit">做预算，尽早把控现场情况</p>
        <p class="p_tit1">
          出具 CAD
          安装图纸，并进行精确的材料统计，给机房预算造价提供依据。杜绝机房材料盲目采购，控制施工成本。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {},

  destroyed() {},
  // 组件方法
  methods: {},
};
</script>

<style scoped lang='less'>
.video_tit {
  color: #ffffff;
  border-top: 1px solid transparent;
  .box {
    width: 1030px;
    height: 264px;
    margin: 48px auto;
    display: flex;
    flex-wrap: wrap;
    //border: 2px solid gray;
    .box_left {
      width: 48%;
      height: 110px;
      //border: 1px solid gray;
      .p_tit {
        color: #ffffff;
        white-space: nowrap;
        word-wrap: normal;
        word-break: keep-all;
        width: 288px;
        height: 45px;
        font-size: 32px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 45px;
        // font-weight: 600;
      }
      .p_tit1 {
        width: 475px;
        height: 56px;
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #92c2ff;
        line-height: 28px;
        margin-top: 9px;
      }
    }
    .box_rig {
      width: 49.5%;
      height: 110px;
      //border: 1px solid gray;
      .p_tit {
        white-space: nowrap;
        word-wrap: normal;
        word-break: keep-all;
        width: 320px;
        height: 45px;
        font-size: 32px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 45px;
        // font-weight: 600;
      }
      .p_tit1 {
        width: 480px;
        height: 56px;
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #92c2ff;
        line-height: 28px;
        margin-top: 9px;
      }
    }
    .box_btm {
      width: 99%;
      height: 110px;
      //border: 1px solid gray;
      .p_tit {
        width: 70%;
        height: 45px;
        color: #ffffff;
        white-space: nowrap;
        word-wrap: normal;
        word-break: keep-all;
        font-size: 32px;
        font-weight: 600;
        line-height: 45px;

        // font-weight: 600;
      }
      .p_tit1 {
        width: 556px;
        height: 56px;
        font-size: 20px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #92c2ff;
        line-height: 28px;
        margin-top: 9px;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }
    }
  }
}
</style>