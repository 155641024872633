<template>
  <div class="brief_introduction">
    <div class="left">
      <p class="left_tit">关于<span style="font-weight: 100">我们</span></p>
      <div class="left_title"></div>
      <p class="left_cen">
               &emsp;&emsp;公司核心团队精炼二十余年的暖通空调安装运维实战经验教训，研发出一整套智慧能源管控系统+边缘AI计算的 PaaS平台，为<span style="color:RGBA(61, 233, 255, 1)">公共建筑中央空调、城镇热力供暖和工业企业低碳减排</span>，提供省心、省钱、安全运营解决方案。</br>
       &emsp;&emsp;业务涵盖建筑能耗计量监控分析、工业能耗计量监控分析、中央空调智慧管控系统、热泵以及各种换热设备的设计安装、节能自控、运行维护，是集设计、销售、安装、维修服务为一体的技术企业。公司基于自身独有的创新技术优势，在金融机构和央企国企的全力支持下采用BOT、EMC、F+EPC+0等多种商业模式为客户提供综合能源投资建设运营服务，</br>
        &emsp;&emsp;公司独有的<span style="color:RGBA(61, 233, 255, 1)">末端智慧管理系统</span>，实现未端所有设备数字化，为<span style="color:RGBA(61, 233, 255, 1)">“按需供给”</span>的控制策略提供了数据支撑。同时推出暖通热力行业独有的<span style="color:RGBA(61, 233, 255, 1)">大屏可视化编辑平台、积木式全中文策略</span>控制编辑软件为全球创新人士，提供开放的小程序共享空间。不借助专业的PLC编程工程师，暖通设计人员自己就可以完成暖通空调项目的数据可视化平台建设和智慧能源管理系统搭建，大大降低数字化暖通系统的建设费用为智慧暖通系统的普及和应用开辟了新的途径
      </p>
    </div>
    <div class="rig">
      <div class="rig_img"><img :src='imgurl1' alt="" /></div>
      <div style="margin-top:0.10417vw"><img :src='imgurl2' alt="" /></div>
      <!-- <div><img src='@/assets/imgs/IMG_7204P .jpg' alt="" /></div>
      <div><img src='@/assets/imgs/IMG_7201P.jpg' alt="" /></div> -->
    </div>
  </div>
</template>
       
<script>
export default {
  data() {
    return {
      imgurl1: require("@/assets/imgs/IMG_7204P .jpg"),
      imgurl2: require("@/assets/imgs/IMG_7201P.jpg"),
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {},

  destroyed() {},
  // 组件方法
  methods: {},
};
</script>

<style scoped lang='less'>

.brief_introduction:hover {
  // box-shadow: 0px 2px 15px 0px rgba(216,216,216,1);
}
.brief_introduction {
  display: flex;
  position: relative;
  // box-shadow: 0px 2px 15px 0px rgba(216,216,216,0.5);
  .left {
    width: 533px;
    height: 572px;
    background-color: rgba(13, 80, 219, 1);
    // margin-top: 85px;
    // margin-left: 10px;
    z-index: 1;
    position: absolute;
    top: 31px;
    .left_tit {
      width: 80%;
      height: 40px;
      margin-left: 24px;
      margin-top: 32px;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      line-height: 40px;
      font-style: normal;
    }
    .left_title {
      width: 65px;
      height: 4px;
      background-color: #ffffff;
      margin-left: 24px;
    }
    .left_cen {
      width: 485px;
      margin-left: 24px;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 30px;
      margin-top: 32px;
      text-align: left;
font-style: normal;
    }
  }
  .rig {
    width: 520px;
    height: 100%;
    z-index: 0;
    position: absolute;
    right: 0px;
    //margin-left: 39px;
    //margin-top: 24px;
    // border: 1px solid gold;
    div {
      width: 100%;
      height: 314px;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>