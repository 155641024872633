<template>
  <div class="application">
    <div class="cen_box">
      <!-- <img src="../../../assets/imgs/qt/img3.png" alt="" /> -->
      <img v-lazy='imgurl' alt="">
    </div>
    <div class="cen_cen">
      <div class="cen_left">
        <p class="p_tit">一次系统存在的问题</p>
        <p class="p_cen">
          由于系统需要满足中央空调主机工作时的最低水流量所以变频器参与能耗的调节十分有限只能通过压差旁通阀来解决未端和主机的水流量四配问题无法实现节能运行的目的
        </p>
        <div class="tit_src">
          <el-link class="link" type="primary"
            >了解更多<i class="icon_ic12"></i
          ></el-link>
        </div>
      </div>
      <div class="cen_rig">
        <p class="p_tit">二次系统存在的问题</p>
        <p class="p_cen">
          完美解决末端与主机所需水流量的矛盾未端系统水完全实现变频降低系统水泵的扬程，降低运行功耗二次水泵与主机联动，主机卸载时停止工作，实现节能运行
        </p>
        <div class="tit_src">
          <el-link class="link" type="primary"
            >了解更多<i class="icon_ic12"></i
          ></el-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgurl: require("@/assets/imgs/qt/img3.png"),
    };
  },
  inject: ["reload"],
  watch: {},
  // 组件初始化
  created() {},
  // 组件实例挂载完毕
  mounted() {},

  destroyed() {},
  // 组件方法
  methods: {},
};
</script>

<style scoped lang='less'>
.application {
  color: #ffffff;
  border-top: 1px solid ghostwhite;
  .cen_box {
    width: 946px;
    height: 277px;
    //border: 2px dashed gray;
    margin: 0 auto;
    margin-top: 24px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .cen_cen {
    width: 946px;
    height: 148px;
    margin: 0 auto;
    // border: 1px dashed gray;
    display: flex;
    margin-top: 32px;
    .cen_left {
      width: 50%;
      height: 100%;
      // border: 1px dashed gray;
      .p_tit {
        width: 60%;
        height: 28px;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #1b1e20;
        line-height: 28px;
        //margin-top: 43px;
      }
      .p_cen {
        width: 406px;
        height: 66px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #3d485d;
        line-height: 22px;
        margin-top: 8px;
      }
      .tit_src {
        width: 115px;
        height: 28px;
        //border: 1px solid gold;
        //margin-left: 81px;
        margin-top: 18px;
        white-space: nowrap;
        word-wrap: normal;
        word-break: keep-all;
        .link {
          font-size: 14px;
          // font-weight: 600;
          margin-top: -20px;
        }
        i {
          color: #0072ff;
          // margin-left: 10px;
          //margin-top: 5px;
          //border: 1px solid gold;
        }
      }
    }
    .cen_rig {
      width: 50%;
      height: 100%;
      // border: 1px dashed gray;
      .p_tit {
        width: 60%;
        height: 28px;
        font-size: 18px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #1b1e20;
        line-height: 28px;
        //margin-top: 43px;
        margin-left: 70px;
      }
      .p_cen {
        width: 406px;
        height: 66px;
        font-size: 14px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #3d485d;
        line-height: 22px;
        margin-top: 8px;
        margin-left: 70px;
      }
      .tit_src {
        width: 115px;
        height: 28px;
        //border: 1px solid gold;
        margin-left: 70px;
        margin-top: 18px;
        white-space: nowrap;
        word-wrap: normal;
        word-break: keep-all;
        .link {
          font-size: 14px;
          // font-weight: 600;
          margin-top: -20px;
        }
        i {
          color: #0072ff;
          // margin-left: 10px;
          //margin-top: 5px;
          //border: 1px solid gold;
        }
      }
    }
  }
}
</style>