<template>
  <div class="btm_carousel">
    <p>客户案例</p>
    <div class="cen_box">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in datalist"
            :key="index"
          >
            <p class="p_tit">{{ item.title }}</p>
            <div class="box">
              <div class="box_img">
                <img :src="item.image" alt="" />
              </div>
              <div class="box_data">{{ item.introduce }}</div>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { customCaseList } from "../../../assets/api/indexl";
// 引入js
import Swiper from "swiper";
// 引入css
import "swiper/css/swiper.min.css";
export default {
  props: {
    datalist: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      // datalist: [
      //   {
      //     title: "邓州市翰林公馆住宅中央空调项目",
      //     introduce: "本项目采用水源热泵 + 冷水机组的方式提供冷热源，共采用1320KW制冷量水源热泵 3 台，2100KW制冷量离心式水冷机组 2 台。总建筑面积120600 ㎡，共计 895 户，一期安装风盘 3168 台、二期安装风盘 1363 台。2021 年 6 月投入使用，采用智慧能源管理系统 + 末端设备集中控制系统，用户采用基础费 + 计量费的收费模式，在入住率不足 20% 的条件下运行，冷暖效果均受到用户一致好评，节能效果明显。",
      //     image: require("@/assets/imgs/qt/img9.png"),
      //   },
      //   {
      //     title: "邓州市人民医院（湍北分院）项目",
      //     introduce: "本项目总建筑156185.78㎡,选用3台制冷量为3519KW的离心式冷水机组；2台制冷量1934Kw,制热量为1954KW的螺杆式地源热泵机组；1台制冷量为728KW，制热量为735KW的螺杆式地源热泵机组；3台2450KW热量的真空负压锅炉，采用多种能源互利的方式，为本项目供冷、供暖。",
      //     image: require("@/assets/imgs/yc_xmt3.png"),
      //   },
      //   {
      //     title: "鼎和汤泉酒店",
      //     introduce: "本项目采用水源热录+冷水机组的方式提供冷热源，共采用1320KW制冷量水源热泉3台，2100KW制冷量商心式水冷机组2台。总建筑面积120600㎡，共计895户，一期安装风盘3168台、二期安装风盘1363台。2021年6月投入使用，采用智慧能源管理系统+末端设备集中控制系统，用户采用基础费+计量费的收费模式，在入住率不足20%的条件下运行，冷暖效果均受到用户一致好评，节能效果明显。",
      //     image: require("@/assets/imgs/yi_xmt1.png"),
      //   },
      //   {
      //     title: "南阳市中心医院新区医院（南阳市中心医院迁建）",
      //     introduce: "本项目总设计供冷负荷为37475kw，采用离心式冷水机组+水源热泵(带热回收)机组提供；供暖负荷为21252kw，采用水源热泵（带热回收）机组+燃气热水锅炉提供，多种能源形式相结合的复合型能源形式，极大地提高了系统的稳定性和可靠性。共采用1888KW制冷量水源热泵4台，6239KW制冷量离心式水冷机组4台。建设总用地面积:244596.12m2拟建项目总建筑面积:391924.32m2，其中地上总建筑面积230097.05m2，地下总建面积11827.27m2",
      //     image: require("@/assets/imgs/62b180844d97e.png"),
      //   },
      // ],
    };
  },
  inject: ["reload"],
  watch: {
    // datalist: {
    //   handler(newss, old) {
    //     console.log(newss , 'newsssss')
    //   },
    //   immediate: false,
    // },
  },
  // 组件初始化
  created() {
    
  },
  // 组件实例挂载完毕
  mounted() {
    this.handelrSwiper();
  },

  destroyed() {},
  // 组件方法
  methods: {
    handelrSwiper() {
      var swiper = new Swiper(".swiper-container", {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 1,
        observer: true,
        loop: true,
        loopFillGroupWithBlank: true,
        // effect: 'fade',
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true,
        // },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    },
  },
};
</script>

<style scoped lang='less'>
.box_data::-webkit-scrollbar {
  display: none;
}
.box_data {
  overflow: hidden;
  overflow-y: scroll;
}
.swiper-button-prev {
  position: absolute;
  left: 43%;
  top: 360px;
  z-index: 999;
  width: 60px;
  height: 60px;
  background-color: rgba(61, 72, 93, 0.8);
  border-radius: 40px;
  --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px; /* 设置按钮大小 */
  // margin-left: 43%;
  // margin-top: 200px;
}
.swiper-button-next {
  position: absolute;
  right: 43%;
  top: 360px;
  z-index: 999;
  background-color: rgba(61, 72, 93, 0.8);
  width: 60px;
  height: 60px;
  border-radius: 40px;
  // --swiper-theme-color: #ff6600; /* 设置Swiper风格 */
  --swiper-navigation-color: #ffffff; /* 单独设置按钮颜色 */
  --swiper-navigation-size: 30px; /* 设置按钮大小 */
}
.swiper-container {
  width: 100%;
  height: 140%;
  //border: 1px solid gold;
}

.swiper-slide {
  // text-align: center;
  font-size: 18px;
  background-color: #ffffff;
  height: 329px;
  width: 587px;
  /* Center slide text vertically */
  // display: -webkit-box;
  // display: -ms-flexbox;
  // display: -webkit-flex;
  // display: flex;
  // -webkit-box-pack: center;
  // -ms-flex-pack: center;
  // -webkit-justify-content: center;
  // justify-content: center;
  // -webkit-box-align: center;
  // -ms-flex-align: center;
  // -webkit-align-items: center;
  // align-items: center;
  .p_tit {
    width: 100%;
    height: 18px;
    font-size: 18px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #3d485d;
    line-height: 18px;
    margin-left: 12px;
    margin-top: 24px;
  }
  .box {
    width: 539px;
    height: 245px;
    display: flex;
    margin-top: -24px;
    margin-left: 12px;
    //border: 1px solid gray;
    .box_img {
      width: 210px;
      height: 245px;
      //border: 1px solid gray;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .box_data {
      width: 309px;
      height: 234px;
      //border: 1px solid gray;
      margin-left: 20px;
      font-size: 14px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #3d485d;
      line-height: 26px;
    }
  }
}

.btm_carousel {
  color: #ffffff;
  border: 1px solid #ffffff;
  p {
    width: 120px;
    height: 42px;
    white-space: nowrap;
    word-wrap: normal;
    word-break: keep-all;
    font-size: 28px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 42px;
    margin: 48px auto;
  }
  //border-top: 1px solid ghostwhite;
  .cen_box {
    width: 93%;
    height: 329px;
    //border: 1px solid goldenrod;
    margin: 0 auto;
  }
}
</style>